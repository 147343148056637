import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/WhoWeAre/banner1.jpg';
import Banner1 from '../../images/landingPage/WhoWeAre/Empowering-Entrepreneurs-Focus.jpg';
import WhyBusiness from '../../images/landingPage/home/WhyBusiness.jpg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Landing.module.scss';
// import { NavHashLink } from 'react-router-hash-link';
// import Video from '../../images/landingPage/home/GSGFl.mp4';
// import Modal from 'react-bootstrap/Modal';
// import JoinOurCommunity from '../../images/landingPage/home/JoinOurCommunity.jpg';
import SponserIcon from '../../images/landingPage/GetInvolved/hand.png';
import MoneyIcon from '../../images/landingPage/home/money.png';

function AboutUs() {
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // const donateUrl = `${process.env.REACT_APP_DONATE_URL}`

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  // const scrollWithOffset = (el) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //   const yOffset = -80; 
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  // }

  return <>
    <Header />
    <section className={`${styles.section1} container-fluid`}>
      <div className={`container py-lg-5 py-xs-0`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>About</span> Us</h2>
            <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">At the heart of our company is a belief in the power of entrepreneurship to address today’s global challenges. We see each individual as an agent of social change, capable of making a meaningful impact by harnessing their strengths and skills.</p>
            <div className='col-sm-12' data-aos="fade-up"><Link to="https://store.gostartupgo.org/index.php/donate/"   target="_blank" className='btn--primary me-2' role='button' aria-label='Support a Business'>Support a Business</Link> </div>
          </div>
          <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end ps-lg-5 ps-xs-0 pe-lg-5 pe-xs-0 position-relative rightEffect'>
            <img src={Banner} alt='Slider' className='w-100 height420 br--30' data-aos="fade-up"/>
          </div>
        </div>
      </div>
    </section>
    {/* <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton className={`${styles.modalHeader} border-0 pb-0`}>
        </Modal.Header>
        <Modal.Body className='text-center p-0 d-flex'>
          <video controls autoPlay width="100%" className='br--5'>
            <source src={Video} type="video/webm"/>
            <source src={Video} type="video/mp4"/>
          </video>
        </Modal.Body>
    </Modal> */}
    <section className={`${styles.section5} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-12 col-xs-12 text-center pb-4 fontPoppins'>
            <div className={`bgGradient--primary py-4 br--999`}>
              <h3 className={`${styles.section5Inner} fs--18 lh-30 fw--400 text-white`} data-aos="fade-up">Our mission is not just to help businesses get started, but to ensure they are truly set up for long-term success. We provide guidance and empower entrepreneurs with the tools they need to thrive in a competitive environment. From understanding how to operate a business to mastering the skills required to succeed, we are committed to supporting each step of the journey.</h3>
            </div>
          </div>
          {/* <div className='col-sm-12 col-xs-12 text-center px-lg-5 px-xs-0 fontPoppins'>
            <p className='fs--18 mb-0 lh--35 text--white3 text-center px-lg-5 px-xs-0' data-aos="fade-up">Job saturation in the private sector and the shrinking public sector in Africa are driving the demand for the startup of the right kind of formal enterprises that will positively impact economic development and reduce poverty in sub-Saharan Africa.</p>
          </div> */}
        </div>
      </div>
    </section>
    <section className={`${styles.section6} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
            <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 mb-0' data-aos="fade-up">Empowering Entrepreneurs Focus and Entrepreneur Trainings​</h3>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35 mb-0 ' data-aos="fade-up">At GoStartUpGo Foundation, we believe in the transformative power of entrepreneurship to address global challenges. As a 501(c)(3) nonprofit, we are dedicated to fostering entrepreneurship, particularly in developing countries, where individuals have the potential to be agents of social change by leveraging their strengths and skills.</p>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35 mb-0 ' data-aos="fade-up">We don’t just help businesses get started—we ensure they are set up for long-term success. Through our <strong>Entrepreneur Training</strong> programs, we equip aspiring entrepreneurs with the tools and knowledge needed to turn their ideas into thriving businesses. Our comprehensive training covers essential topics such as financial management, marketing, and product development to help entrepreneurs build a solid foundation.​</p>            
          </div>
          <div className='col-sm-6 col-xs-12  ps-lg-5 ps-xs-0'>
            <img src={Banner1} alt='Empowering Entrepreneurs Focus' className='w-100 h-auto br--10' data-aos="fade-right" />
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section4} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-12 col-xs-12 text-center pb-5 fontPoppins'>
            <h3 className='fs--38 lh--45 fw--600' data-aos="fade-up"><span className='text--primary'>What</span> We Do </h3>
            <p className='col-sm-10 col-xs-12 offset-sm-1 fs--18 lh--45 text--white3' data-aos="fade-up">Our <strong>Empowering Entrepreneurs Focus</strong> is grounded in providing hands-on training, ongoing mentorship, and access to funding. We believe in offering lasting solutions, enabling entrepreneurs to not only start but grow sustainable businesses that create jobs and foster economic development in their communities.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 order-lg-1 order-xs-2'>
            <img src={WhyBusiness} alt='Our Approach' className='w-100 h-auto br--10' data-aos="fade-right" />
          </div>
          <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center order-lg-2 order-xs-1'>
            <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 mb-0' data-aos="fade-up">Our Approach and Mentorship</h3>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35 mb-0 pe-lg-5 pe-xs-0' data-aos="fade-up">At GoStartUpGo, we know that success doesn't stop at training. Every entrepreneur in our program is paired with an experienced mentor—a seasoned business leader who provides guidance, support, and expertise. These mentors help entrepreneurs navigate challenges, scale their businesses, and stay on the path to sustainable growth.​</p>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35 mb-0 pe-lg-5 pe-xs-0' data-aos="fade-up">Our focus is on providing hands-on training, ongoing mentorship, and access to funding, empowering entrepreneurs to build sustainable businesses that create jobs and positively impact their communities. We believe that their success will ripple through families and neighborhoods, driving long-term social and economic development.​​</p>
          </div>
        </div>
      </div>
    </section>
    <section className={`container-fluid bg--grey1 py-3`}>
            <div className={`container py-5`}>
                <div className={`d-grid pt-2 ${styles.gridContainer}`}>
                    <div className={`br--10 bg-white px-4 py-3 br--10 text-center pb-4`}>
                        <div className={`${styles.icon} p-4 mb-4 bg--primary`}>
                            <img src={MoneyIcon} alt='Volunteer'/>
                        </div>
                        <h3 className='fs--30 fw--600 pb-2 text-black fontPoppins lh-45' data-aos="fade-up">Access to Funding​ </h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35' data-aos="fade-up">Entrepreneurs need capital to succeed, and GoStartUpGo helps provide that through grants, microloans, and business development support. Every dollar goes toward setting up sustainable, community-focused businesses.  </p>
                        <div className='col-sm-12 mt-4' data-aos="fade-up"><Link to={"https://store.gostartupgo.org/index.php/donate/"} target='_blank' className='btn--secondary me-2' role='button' aria-label='Make an Impact'>Make an Impact</Link> </div>
                    </div>
                    <div className={`br--10 bg-white px-4 py-3 br--10 text-center pb-4`}>
                        <div className={`${styles.icon} p-4 mb-4 bg--primary`}>
                            <img src={SponserIcon} alt='Volunteer'/>
                        </div>
                        <h3 className='fs--30 fw--600 pb-2 text-black fontPoppins lh-45' data-aos="fade-up">Become a Sponsor​ </h3>
                        <p className='fs--18 text-black fontPoppins lh-35' data-aos="fade-up">Directly impact an entrepreneur's future by sponsoring their journey. Choose from the following sponsorship levels: </p>
                        <ul className='list-group'>
                            <li className='list-group-item' data-aos="fade-up"><strong>$100: </strong>Provides an entrepreneur with vital training.</li>
                            <li className='list-group-item' data-aos="fade-up"><strong>$250: </strong>Helps fund essential startup materials.</li>
                            <li className='list-group-item' data-aos="fade-up"><strong>$500: </strong>Fully sponsors an entrepreneur’s journey from training to business launch.</li>
                        </ul>
                        <div className='col-sm-12 mt-4' data-aos="fade-up"><Link to="https://store.gostartupgo.org/index.php/donate/" target='_blank' className='btn--secondary me-2' role='button' aria-label='Make an Impact'>Make an Impact</Link> </div>
                    </div>
                </div>
            </div>
        </section>
    <section className={`${styles.section10} container-fluid bgGradient--primary`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--30 mb-3 fontMontserrat text-white fw--600 lh-38' data-aos="fade-up">Go Start Up Go is the ideal partner to turn your small business dreams into reality.</h2>
            <p className='fs--16 text--grey1 mb-0 fontMontserrat lh-25' data-aos="fade-up">With Go Start Up Go, you'll have the support and tools necessary to be the best version of yourself and realize your full potential.</p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <Copyright />
  </>
}

export default AboutUs